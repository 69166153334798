<template>
    <div class="topBox" >
       <div style="width:1400px; display: flex; justify-content: space-between;margin: 0 auto; background-color: #25459C;">
        <img src="../assets/netseaWirte.png" alt="" style="width: 180px;height:80px"> 
       <div class="topRight" v-if="userInfo.nickname">
         <div class="outLogin" >
           <el-popconfirm width="220" confirm-button-text="确认退出"  @confirm="confirmLogin" confirm-button-type="none"
              cancel-button-text="取消" icon-color="#626AEF" title="你确定要退出登录吗?">
              <template #reference>
                <div class="flex">
                  <img :src="userInfo.avatar" alt="" />
                  <span class="">{{ userInfo.nickname }}</span>
                </div>
              </template> 
            </el-popconfirm>
         </div>
       </div>
       </div>
    </div>
  </template>
  <script  setup>
    import { reactive} from 'vue'
    const userInfo=reactive({
      nickname:localStorage.getItem('userInfocustomer')?JSON.parse(localStorage.getItem('userInfocustomer')).account:'',
      avatar:localStorage.getItem('userInfocustomer')?JSON.parse(localStorage.getItem('userInfocustomer')).image:'',
    })
    const confirmLogin=()=>{
        localStorage.clear()
        location. reload()
    }
  </script>
  <style lang="scss" scoped>
  .topBox{
    background-color: #25459C;
    // padding: 0 300px;
    box-sizing: border-box;
    .topRight{
      color: #fff;
      font-size: 30px;
      position: relative;
      display: flex;
      align-items: center;
     
      .outLogin{
        img{
        width: 61px;
        height: 61px;
        border-radius: 50%;
        margin-right: 30px;
      }
        
      }
    }
  }

  </style>