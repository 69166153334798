const constant: any = {
  typeC2C: 'isC2C',
  typeGroup: 'isGroup',
  cancel: 'cancel',
  group: 'GROUP',
  handleMessage: {
    revoke: 'revoke',
    copy: 'copy',
    delete: 'delete',
    forward: 'forward',
    reply: 'reply',
  },
  at: '@',
  all: '所有人',
  typeText: 1,
  typeCustom: 2,
  typeImage: 3,
  typeAudio: 4,
  typeVideo: 5,
  typeFile: 6,
  typeFace: 8,
  typeForward: 'forward',
  typeMute: 'mute',
  typeOrder: 'order',
  typeService: 'consultion',
  typeReply: 'quick_reply',
  typeEvaluate: 'evaluation',
  typeTextLink: 'text_link',
  typeAndroid: 'android',
  typeIphone: 'iphone',
  typeMini: 'miniprogram',
  typeUserTyping: 'user_typing_status',
  typeInputStatusIng: 'EIMAMSG_InputStatus_Ing',
  typeInputStatusEnd: 'EIMAMSG_InputStatus_End',
  scrollType: {
    toBottom: 'bottom',
    toTarget: 'target',
  },
  inViewType: {
    page: 'page',
    scroll: 'scroll',
  },
  TYPE_CALL_MESSAGE: 1,
  CALL_ACTION_TYPE: {
    INVITE: 1,
    CANCEL_INVITE: 2,
    ACCEPT_INVITE: 3,
    REJECT_INVITE: 4,
    INVITE_TIMEOUT: 5,
  },
};

export default constant;
