<template>
  <div class="boix">
    <img src="./assets/20230214110304.png" alt="">
    <div class="content">
      <span style="font-size:28px;font-weight:700">奈特喜客服端</span>
      <el-input style="height:50px;margin: 40px 0 20px;" v-model="userInfo.user" placeholder="请输入账号"
        :prefix-icon="User" />
      <el-input style="height:50px" v-model="userInfo.pass" placeholder="请输入密码" :prefix-icon="Lock" show-password />
      <el-button style="background:#25459C;color: #fff;border: none;width: 100%;height: 50px;margin-top: 30px;"
        @click="login">登录</el-button>
    </div>
  </div>
</template>
<script  setup>
import { reactive } from 'vue'
import { User, Lock } from '@element-plus/icons-vue'
import { TUIComponents, TUICore, genTestUserSig } from './TUIKit';
const userInfo = reactive({
  nickname: 'fds',
  avatar: '',
  user: "",
  pass: '',
})
const login = () => {
  var xhr = new XMLHttpRequest();
  xhr.onreadystatechange = function () {
    if (xhr.readyState == 4) {
      let info = JSON.parse(xhr.responseText)
      console.log(info.data.customer, '.message');
      if (info.code == 200) {
        localStorage.setItem('userInfocustomer', JSON.stringify(info.data.customer))
        const SDKAppID = 1400767345; // Your SDKAppID
        const secretKey = '0884990c96d14f171ef8e73c43743274815b3fb40b69d91848d79bf176ce0d64'; //Your secretKey
        let userID = info.data.customer.tencent_user_id; // User ID
        const TUIKit = TUICore.init({
          SDKAppID,
        });
        TUIKit.use(TUIComponents);
        if (localStorage.getItem('userInfocustomer')) {
          const userInfocustomer = localStorage.getItem('userInfocustomer')
          const a = JSON.parse(userInfocustomer)
          userID = a.tencent_user_id
        }
        location.reload()
      } else {
        alert(info.message)
      }

    }
  }
  xhr.open('POST', 'https://api.aucfan-cn.com/customer/login/login');
  //需要加请求头, 设置请求头
  xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset-UTF-8');
  xhr.send(`account=${userInfo.user}&password=${userInfo.pass}`);
}
</script>
<style lang="scss" scoped>
.boix {
  // padding: 200px 300px 0 200px;
  padding-top: 100px;
  width: 1400px;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    width: 800px;
  }
}

.content {
  text-align: center;
  width: 380px;
  padding: 40px 40px 100px;
  box-sizing: border-box;
  //  margin-top: 300px;
  box-shadow: 0 0 5px #ccc;

}
</style>