import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Navvue = _resolveComponent("Navvue")!
  const _component_HelloWorld = _resolveComponent("HelloWorld")!
  const _component_Login = _resolveComponent("Login")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Navvue),
    (_ctx.customer)
      ? (_openBlock(), _createBlock(_component_HelloWorld, {
          key: 0,
          msg: "Welcome to Your Vue.js + TypeScript App"
        }))
      : (_openBlock(), _createBlock(_component_Login, { key: 1 }))
  ], 64))
}