
import { computed, defineComponent, reactive, toRefs, watch } from 'vue';
import MessageSystem from './components/message-system.vue';
import { handleErrorPrompts, isArrayEqual } from '../utils';
import { useStore } from 'vuex';

const TUIContact = defineComponent({
  name: 'TUIContact',
  components: {
    MessageSystem,
  },

  setup(props: any, ctx: any) {
    const TUIServer: any = TUIContact.TUIServer;
    const { t } = TUIServer.TUICore.config.i18n.useI18n();
    const VuexStore = useStore && useStore();
    const data = reactive({
      groupList: [],
      searchGroup: {},
      searchID: '',
      currentGroup: null,
      systemConversation: {
        unreadCount: 0,
      },
      systemMessageList: [],
      columnName: '',
      types: TUIServer.TUICore.TIM.TYPES,
      isSearch: false,
      env: TUIServer.TUICore.TUIEnv,
      friendList: [],
      userIDList: [],
      currentFriend: {},
      displayOnlineStatus: VuexStore?.state?.displayOnlineStatus,
      onlineStatus: false,
      userStatusList: new Map(),
    });

    TUIServer.bind(data);

    watch(
      () => VuexStore?.state?.displayOnlineStatus,
      async (newVal: any, oldVal: any) => {
        if (newVal === oldVal) return;
        data.displayOnlineStatus = newVal;
        TUIServer.handleUserStatus(data.displayOnlineStatus, data.userIDList);
      }
    );

    watch(
      () => data.userIDList,
      (newVal: Array<string>, oldVal: Array<string>) => {
        if (isArrayEqual(newVal, oldVal)) return;
        TUIServer.handleUserStatus(data.displayOnlineStatus, data.userIDList);
      }
    );

    const isNeedPermission = computed(() => {
      const isHaveSeif = (data.currentGroup as any).selfInfo.userID;
      const isPermission =
        (data.currentGroup as any).joinOption === TUIServer.TUICore.TIM.TYPES.JOIN_OPTIONS_NEED_PERMISSION;
      return !isHaveSeif && isPermission;
    });

    const handleListItem = async (item: any) => {
      switch (data.columnName) {
        case 'group':
          data.currentGroup = item;
          break;
        case 'friend':
          data.currentFriend = item;
          break;
      }
      if (data.isSearch) {
        data.currentGroup = item;
      }
    };

    const handleSearchGroup = async (e: any) => {
      data.currentGroup = null;
      if (data.searchID.trim()) {
        try {
          await TUIServer.searchGroupByID(data.searchID.trim());
        } catch (error) {
          const message = t('TUIContact.该群组不存在');
          handleErrorPrompts(message, data.env);
        }
      }
    };

    const join = async (group: any) => {
      const options: any = {
        groupID: group.groupID,
        applyMessage: group.applyMessage || t('TUIContact.加群'),
        type: group?.type,
      };
      await TUIServer.joinGroup(options);
      (data.currentGroup as any).apply = true;
    };

    const quit = async (group: any) => {
      await TUIServer.quitGroup(group.groupID);
      data.currentGroup = null;
    };

    const enter = async (ID: any, type: string) => {
      const name = `${type}${ID}`;
      TUIServer.TUICore.TUIServer.TUIConversation.getConversationProfile(name).then((imResponse: any) => {
        // 通知 TUIConversation 添加当前会话
        // Notify TUIConversation to toggle the current conversation
        TUIServer.TUICore.TUIServer.TUIConversation.handleCurrentConversation(imResponse.data.conversation);
        back();
      });
    };

    const dismiss = (group: any) => {
      TUIServer.dismissGroup(group.groupID);
      data.currentGroup = null;
    };

    const select = async (name: string) => {
      if (data.columnName !== 'system' && name === 'system' && (data.systemConversation as any)?.conversationID) {
        await TUIServer.getSystemMessageList();
        await TUIServer.setMessageRead();
      }
      (data.currentGroup as any) = {};
      if (data.columnName !== 'group' && name === 'group' && !data.env.isH5) {
        (data.currentGroup as any) = data.groupList[0];
      } else {
        (data.currentGroup as any) = {};
      }
      data.searchID = '';
      data.columnName = data.columnName === name ? '' : name;
    };

    const toggleSearch = () => {
      data.isSearch = !data.isSearch;
      data.columnName = '';
      data.searchID = '';
      data.searchGroup = {};
      (data.currentGroup as any) = {};
    };

    const init = () => {
      data.isSearch = false;
      data.columnName = '';
      data.searchID = '';
      data.searchGroup = {};
      (data.currentGroup as any) = {};
    };

    const handleGroupApplication = (params: any) => {
      TUIServer.handleGroupApplication(params);
    };
    const back = () => {
      (data.currentGroup as any) = {};
      data.columnName = '';
    };

    const getUserStatusList = (userList: Array<string>) => {
      TUIServer.TUICore.getUserStatusList(userList);
    };

    return {
      ...toRefs(data),
      handleListItem,
      handleSearchGroup,
      join,
      quit,
      dismiss,
      isNeedPermission,
      select,
      handleGroupApplication,
      toggleSearch,
      init,
      back,
      enter,
      getUserStatusList,
    };
  },
});
export default TUIContact;
