
import { defineComponent } from 'vue';
import HelloWorld from './components/HelloWorld.vue';
import Login from './login.vue';
import Navvue from './components/nav.vue'
export default defineComponent({
  name: 'App',
  components: {
    Login,HelloWorld,Navvue
  },
  data(){
    return{
       customer :localStorage.getItem('userInfocustomer')?true:''
    }
  }
});
