import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-987cbc24"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "call",
  id: "call"
}
const _hoisted_2 = { class: "uncall-dialog-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Transfer = _resolveComponent("Transfer")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_ctx.handleShowCallIcon(_ctx.conversation, _ctx.isH5))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Dialog, {
          show: _ctx.showGroupUserDialog,
          isH5: _ctx.isH5,
          isHeaderShow: false,
          isFooterShow: false,
          background: false,
          "onUpdate:show": _cache[0] || (_cache[0] = (e) => (_ctx.showGroupUserDialog = e))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Transfer, {
              isSearch: true,
              title: _ctx.showTitle,
              list: _ctx.searchUserList,
              isH5: _ctx.isH5,
              isRadio: _ctx.conversation?.type === 'isC2C',
              onSearch: _ctx.handleSearch,
              onSubmit: _ctx.submit,
              onCancel: _ctx.cancle
            }, null, 8, ["title", "list", "isH5", "isRadio", "onSearch", "onSubmit", "onCancel"])
          ]),
          _: 1
        }, 8, ["show", "isH5"]),
        _createVNode(_component_Dialog, {
          show: _ctx.showUnsupportDialog,
          isH5: _ctx.isH5,
          isHeaderShow: true,
          isFooterShow: true,
          background: true,
          title: _ctx.$t('TUIChat.欢迎使用TUICallKit'),
          "onUpdate:show": _cache[2] || (_cache[2] = (e) => (_ctx.showUnsupportDialog = e))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("p", null, _toDisplayString(_ctx.errorContent), 1),
                _withDirectives(_createElementVNode("p", null, [
                  _createTextVNode(_toDisplayString(_ctx.$t('TUIChat.请点击')) + " ", 1),
                  _createElementVNode("a", {
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openLink(_ctx.errorLink?.url)))
                  }, _toDisplayString(_ctx.$t(`TUIChat.${_ctx.errorLink?.label}`)), 1),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('TUIChat.进行体验')), 1)
                ], 512), [
                  [_vShow, Object.keys(_ctx.errorLink).length > 0]
                ])
              ])
            ])
          ]),
          _: 1
        }, 8, ["show", "isH5", "title"])
      ]))
    : _createCommentVNode("", true)
}