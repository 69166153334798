import { createApp } from 'vue';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue';
import { TUIComponents, TUICore, genTestUserSig } from './TUIKit';

const SDKAppID = 1400767345; // Your SDKAppID
const secretKey = '0884990c96d14f171ef8e73c43743274815b3fb40b69d91848d79bf176ce0d64'; //Your secretKey
let userID = ''; // User ID


// init TUIKit
const TUIKit = TUICore.init({
  SDKAppID,
});
// TUIKit add TUIComponents
TUIKit.use(TUIComponents);
if (localStorage.getItem('userInfocustomer')) {
  const userInfocustomer:any=localStorage.getItem('userInfocustomer')
  const a:any= JSON.parse(userInfocustomer)   
  userID=a.tencent_user_id
}
if(userID){
// login TUIKit
TUIKit.login({
  userID: userID,
  userSig: genTestUserSig({
    SDKAppID,
    secretKey,
    userID,
  }).userSig, // The password with which the user logs in to IM. It is the ciphertext generated by encrypting information such as userID.For the detailed generation method, see Generating UserSig
});
}

createApp(App).use(ElementPlus).use(TUIKit).mount('#app');




// import { createApp } from 'vue';
// import App from './App.vue';
// import ElementPlus from 'element-plus'
// import 'element-plus/dist/index.css'
// import { TUIComponents, TUICore, genTestUserSig } from './TUIKit';
// const SDKAppID = 1400767345; // Your SDKAppID
// let secretKey = '0884990c96d14f171ef8e73c43743274815b3fb40b69d91848d79bf176ce0d64'; //Your secretKey
// let userID = 'CUSTOMER_2 '; // User ID

// if (localStorage.getItem('userInfocustomer')) {
//   const userInfocustomer:any=localStorage.getItem('userInfocustomer')
//   const a:any= JSON.parse(userInfocustomer)   
//   userID=a.tencent_user_id
//   secretKey=a.userSig
// }
// const TUIKit = TUICore.init({
//   SDKAppID,
// });
// console.log(userID,'userIDuserIDuserIDuserIDuserIDuserIDuserIDuserIDuserID')
// if(userID){
//   TUIKit.login({
//     userID: userID,
//     userSig: genTestUserSig({
//       SDKAppID,
//       secretKey,
//       userID,
//     }).userSig, // The password with which the user logs in to IM. It is the ciphertext generated by encrypting information such as userID.For the detailed generation method, see Generating UserSig
//   });
// }

// TUIKit.use(TUIComponents); 
// createApp(App).use(ElementPlus).use(TUIKit).mount('#app');


